import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { IProfileLinks } from '../../../models/profilelink.model';
import { IProduct } from './header.model';

@Injectable()
export class HeaderService {

    constructor(private http: HttpClient) {
    }

    getAllUserProfileLinks(apiUrl: string): Observable<IProfileLinks[]> {
        return this.http.get<IProfileLinks[]>(`${apiUrl}/Products/getAllUserProfileLinks`).pipe(
          // tap(data => console.log('Header API Data: ' + JSON.stringify(data))),
          catchError(this.handleError)
        );
    }

    getEntitledUserProfileLinks(userId: string, token: string, apiUrl: string): Observable<IProfileLinks[]> {
        return this.http.get<IProfileLinks[]>(`${apiUrl}/Products/getEntitledUserProfileLinks?token=${token}&userId=${userId}`).pipe(
          // tap(data => console.log('Header API Data: ' + JSON.stringify(data))),
          catchError(this.handleError)
        );
    }

    getUserProducts(userId: string, token: string, apiUrl: string): Observable<IProduct[]> {
        return this.http.get<IProduct[]>(`${apiUrl}/Products/getUserProducts?userId=${userId}&token=${token}`).pipe(
          // tap(data => console.log('UserProducts API Data: ' + JSON.stringify(data))),
          catchError(this.handleError)
        );
    }

    getAllProducts(apiUrl: string) {
        return this.http.get(`${apiUrl}/Products/getAllProducts`).pipe(
          // tap(data => console.log('AllProducts API Data: ' + JSON.stringify(data))),
          catchError(this.handleError)
        );
    }
/*
    getAllProducts(homePageApiUrl: string) {
        return this.http.get("../../../assets/jsons/all_products.json");
    }
*/

    public handleError(err: HttpErrorResponse) {
        let errorMessage = '';
        if (err.error instanceof ErrorEvent) {
            errorMessage = `An error occurred: ${err.error.message}`;
        } else {
            errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
        }
        console.error(errorMessage);
        return throwError(errorMessage);
    }
}
