import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { IConfig } from 'src/models/config';
import { INavigationResponse, Parameter } from 'src/models/dashboard-config.model';
import { IFilter } from 'src/models/filter.model';
import { map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppService {
   globalConfig = '../assets/jsons/config.json';
   appConfigLoaded: BehaviorSubject<boolean> = new BehaviorSubject(false);
  public appConfig: IConfig;

  constructor(private http: HttpClient) {
    this.fetchAppConfig();
  }
  async fetchAppConfig() {
    this.appConfig = await this.http.get<IConfig>(this.globalConfig).toPromise();
    this.appConfigLoaded.next(true);
  }

  getFilterValues(ticket: string, dashboardId: number): Observable<IFilter[]> {
    return this.http.get<any>(`${this.appConfig.apiUrl}/Filter/GetFilters/${dashboardId}/${ticket}`).pipe(
      map(data => {
        console.log('getFilterValues: ' + JSON.stringify(data));
        return data;
      }),
      catchError(this.handleError)
    );
  }

  getNavigation(ticket: string): Observable<INavigationResponse> {
    return this.http.get<INavigationResponse>(`${this.appConfig.apiUrl}/Navigation/GetNavigation/${ticket}`).pipe(
      map(data => {
        console.log('getNavigation: ' + JSON.stringify(data));
        return data;
      }),
      catchError(this.handleError)
    );
  }

  public handleError(err: HttpErrorResponse) {
    let errorMessage = '';
    if (err.error instanceof ErrorEvent) {
      errorMessage = `An error occurred: ${err.error.message}`;
    } else {
      errorMessage = `Server returned code: ${err.status}, error message is: ${err.message}`;
    }
    console.error(errorMessage);
    return throwError(errorMessage);
  }
  getConfigStatus(): Observable<boolean> {
    return this.appConfigLoaded.asObservable();
  }

  getGlobalConfig(): IConfig {
    return this.appConfig;
  }

  getDashboardParameters(id: number): Observable<Parameter[]> {
    return this.http.get<Parameter[]>(`${this.appConfig.apiUrl}/Parameter/GetParameters/${id}`).pipe(
      map(data => {
        console.log('GetParameters: ' + JSON.stringify(data));
        return data;
      }),
      catchError(this.handleError)
    );
  }
}
