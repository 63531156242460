export const header_resources = {
    en: {
        menu: 'menu',
        person: 'person'
    }
};

export const home_resources = {
    en: {
        download: 'Download'
    }
};