import { Component } from '@angular/core';
import { AuthConfig } from 'angular-oauth2-oidc';
import { OAuthService } from 'angular-oauth2-oidc';
import { JwksValidationHandler } from 'angular-oauth2-oidc';
import { Router, Event, NavigationEnd } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { EntitlementService } from './shared/service/entitlement.service';
import { AppService } from './app.service';
import { IConfig } from 'src/models/config';
import { NgxSpinnerService } from "ngx-spinner";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {

  title = 'ISO Experience Profiler';

  authConfig: AuthConfig = { requireHttps : false };
  isAuthenticated = false;
  errorMessage: any;
  customerSuportUrl: string;
  appConfig: IConfig;

  ticket: string;
  cooksrv: string;

  constructor(private oauthService: OAuthService,
              private router: Router, private spinner: NgxSpinnerService,
              private entitlementService: EntitlementService,
              private appService: AppService,
              private cookieService: CookieService) {
    this.router.events.subscribe((routerEvent: Event) => {
      this.checkRouterEvent(routerEvent);
    });

    this.appService.getConfigStatus().subscribe(
      configStatus => {
        if (configStatus) {
          this.appConfig =  this.appService.getGlobalConfig();
          this.authConfig.issuer = this.appConfig.identityUrl;
          this.authConfig.redirectUri = this.getRedirectUrl(this.appConfig.redirectUrl);
          this.authConfig.clientId = this.appConfig.clientId;
          this.authConfig.scope = 'openid';
          this.authConfig.logoutUrl = this.appConfig.identityUrl + '/connect/endsession';
          this.customerSuportUrl = this.appConfig.veriskCustomerSupport;
          this.configureWithConfigApi();
          this.setAuthStatus();
        }
      },
      error => this.errorMessage = error as any
    );
  }
  getRedirectUrl(redirectUrl: string): string {
    const regexUrl = new RegExp(redirectUrl);
    if (regexUrl.test(window.location.href)) {
      return regexUrl.exec(window.location.href)[0].replace('.iso.com','.verisk.com') + '/?domainMigrated=true';
    }
    return window.location.href.replace('.iso.com','.verisk.com') + '/?domainMigrated=true';
  }
  setAuthStatus() {
    this.entitlementService.getLoginStatus().subscribe(
      status => {
        this.isAuthenticated = status;
      }
    );
  }
  private configureWithConfigApi() {
    this.oauthService.setStorage(sessionStorage);
    this.oauthService.configure(this.authConfig);
    this.oauthService.tokenValidationHandler = new JwksValidationHandler();
    this.oauthService.loadDiscoveryDocumentAndTryLogin();
  }

  checkRouterEvent(routerEvent: Event): void {
    if (routerEvent instanceof NavigationEnd) {
      this.cooksrv = this.cookieService.get('ticket');
      if (this.cooksrv) {
        if (this.oauthService.getIdToken()) {
          this.entitlementService.setLoginStatus(true);
        } else if (!new RegExp('#id_token=.+').test(location.hash)) {
          this.entitlementService.setLoginStatus(false);
          this.oauthService.initImplicitFlow();          
        }
      } else {
        if (this.oauthService.getIdToken()) {
          this.oauthService.logoutUrl = JSON.parse(sessionStorage.getItem('id_token_claims_obj')).iss + '/connect/endsession';
          // 'https://idt.iso.com/identity/connect/endsession'
          this.oauthService.logOut();
        }  else {
          this.oauthService.initImplicitFlow();        
        }
      }
    }
  }
}
