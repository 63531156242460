import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LOBModel } from '../../../models/profile.experience.model';

@Injectable({
    providedIn: 'root', // root level, angular creates a single shared instance of this service
  })
  export class FileService {

    constructor(private http: HttpClient) { }

    getFilesList(ticket: string, apiUrl: string): Observable<LOBModel[]>{
      return this.http.get<LOBModel[]>(`${apiUrl}/ExperienceProfiler?ticket=${ticket}`);        
    }
  }